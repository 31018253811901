var path = require('./path');
var util = require('./util');

jQuery.support.cors = true;
export function http(param) {
  param.params = $.extend(
    {
      userid:
        util.getCookie('userInfo') &&
        JSON.parse(util.getCookie('userInfo')).userid,
    },
    param.params
  );
  return $.ajax({
    type: param.method || 'post',
    url: param.url || '',
    dataType: param.type || 'json',
    data: param.params || '',
    contentType:
      param.contentType || 'application/x-www-form-urlencoded;charset=UTF-8',
    // traditional: param.contentType || false,
    xhrFields: {
      withCredentials: true,
    },
    crossDomain: true,
    beforeSend: function(request) {
      // console.log('request getToken:',getToken());
      //   if (util.getCookie('Authorization')) {
      //     request.setRequestHeader(
      //       'Authorization',
      //       util.getCookie('Authorization')
      //     );
      //   } else {
      //     request.setRequestHeader('Authorization', '');
      //   }
    },
    // success     : function(res){
    //     // 请求成功
    //     if(200 === res.status){
    //         if(res.errorCode!==0){
    //         // console.log(res.token)
    //          // _this.doLogin();
    //             typeof param.error === 'function' && param.error(res);
    //         }else{
    //             typeof param.success === 'function' && param.success(res);
    //         }

    //      }else{
    //         typeof param.error === 'function' && param.error(res);
    //      }
    // },
    // error       : function(err){
    //     typeof param.error === 'function' && param.error(err);
    // }
  });
}

export function postAction(url, param) {
  return $.ajax({
    url: url,
    type: 'post',
    data: $.extend({}, param),
    contentType:
      (param && param.contentType) ||
      'application/x-www-form-urlencoded;charset=utf-8',
  });
}

//get
export function getAction(url, param) {
  return $.ajax({
    url: url,
    type: 'GET',
    dataType: 'json',
    data: $.extend({}, param),
  });
}
