if (!document.getElementsByClassName) {
  document.getElementsByClassName = function(className, element) {
    var children = (element || document).getElementsByTagName('*');
    var elements = new Array();
    for (var i = 0; i < children.length; i++) {
      var child = children[i];
      var classNames = child.className.split(' ');
      for (var j = 0; j < classNames.length; j++) {
        if (classNames[j] == className) {
          elements.push(child);
          break;
        }
      }
    }
    return elements;
  };
}
function parse_query_string(query) {
  var vars = query.split('&');
  var query_string = {};
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    var key = decodeURIComponent(pair[0]);
    var value = decodeURIComponent(pair[1]);
    if (typeof query_string[key] === 'undefined') {
      query_string[key] = decodeURIComponent(value);
    } else if (typeof query_string[key] === 'string') {
      var arr = [query_string[key], decodeURIComponent(value)];
      query_string[key] = arr;
    } else {
      query_string[key].push(decodeURIComponent(value));
    }
  }
  return query_string;
}
function loadHeaderStyle() {
  var query = window.location.search.substring(1);
  var pathnameArr = window.location.pathname.split('.')[0].split('_');
  if (pathnameArr[1]) {
    var index = pathnameArr[1];
  } else {
    var qs = parse_query_string(query);
    var index = qs.index;
  }
  $($('.navbar-ydb-nav .nav-item')[index])
    .addClass('active')
    .siblings('.nav-item')
    .removeClass('active');
}
function callback(result) {}
//兼容IE9以下dataset
function getDataset(ele) {
  if (ele.dataset) {
    return ele.dataset;
  } else {
    var attrs = ele.attributes, //元素的属性集合
      dataset = {},
      name,
      matchStr;
    for (var i = 0; i < attrs.length; i++) {
      //是否是data- 开头
      matchStr = attrs[i].name.match(/^data-(.+)/);
      if (matchStr) {
        //data-auto-play 转成驼峰写法 autoPlay
        name = matchStr[1].replace(/-([\da-z])/gi, function(all, letter) {
          return letter.toUpperCase();
        });
        dataset[name] = attrs[i].value;
      }
    }
    return dataset;
  }
}
function supportForEach() {
  if (!Array.prototype.forEach) {
    Array.prototype.forEach = function forEach(callback, thisArg) {
      var T, k;
      if (this == null) {
        throw new TypeError('this is null or not defined');
      }
      var O = Object(this);
      var len = O.length >>> 0;
      if (typeof callback !== 'function') {
        throw new TypeError(callback + ' is not a function');
      }
      if (arguments.length > 1) {
        T = thisArg;
      }
      k = 0;
      while (k < len) {
        var kValue;
        if (k in O) {
          kValue = O[k];
          callback.call(T, kValue, k, O);
        }
        k++;
      }
    };
  }
}
supportForEach();
module.exports = {
  parse_query_string: parse_query_string,
  loadHeaderStyle: loadHeaderStyle,
  callback: callback,
  getDataset: getDataset,
};
