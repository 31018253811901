import { getAction, postAction, http } from './http';
var path = require('./path');

export const getInformationPager = data =>
  postAction(`${path.url}/api/YunDuanBao/GetInformationPager`, data);
export const getInformationClaList = data =>
  postAction(`${path.url}/api/YunDuanBao/GetInformationClaList`, data);
export const getNewsInfoPager = data =>
  postAction(`${path.url}/api/YunDuanBao/getNewsInfoPager`, data);
export const getInformationFilter = data =>
  postAction(`${path.url}/api/YunDuanBao/GetInformationFilter`, data);
export const getAdList = data =>
  postAction(`${path.url}/api/YunDuanBao/getAdList`, data);
export const getCompanyHistory = data =>
  postAction(`${path.url}/api/YunDuanBao/getCompanyHistory`, data);
export const GetJobInfoPagerPC = data =>
  postAction(`${path.url}/api/YunDuanBao/GetJobInfoPagerPC`, data);
export const MessageMyResumePC = data =>
  postAction(`${path.url}/api/YunDuanBao/MessageMyResumePC`, data);
export const GetJobAddressPC = data =>
  postAction(`${path.url}/api/YunDuanBao/GetJobAddressPC`, data);
export const GetJobTitlePC = data =>
  postAction(`${path.url}/api/YunDuanBao/GetJobTitlePC`, data);
export const UploadImg = data =>
  postAction(`${path.url}/api/UploadFile/UploadImg`, data);
export const imgCode = data =>
  getAction(`${path.url}/api/YunDuanBao/ValidateCode`, data);
export const sendWechatCode = data =>
  postAction(`${path.url}/api/YunDuanBao/SendWechatCodeAsync`, data);
export const MessageMyResume = data =>
  postAction(`${path.url}/api/YunDuanBao/MessageMyResumePC`, data);
