import 'bootstrap';
// import '../scss/bootstrap.less';
import '../scss/index.less';
import { getDataset } from '../common/util';
var util = require('../common/util');
var _ = require('underscore');

var header = require('../component/header.html');
var footer = require('../component/footer.html');
import { getAdList, getInformationPager } from '../common/api';
$('#alert').click(function() {
  alert('jQuery works!');
});

$('#header').append(header);


if(location.host.indexOf('new') > -1){
  $('#news-zixun').show();
  $('#ydb-zixun').hide();
}
$('#footer').append(footer);
$('.index-item').show();
$('#myCarousel').collapse({
  interval: 1000,
});
util.loadHeaderStyle();

$('#myTabs a').click(function(e) {
  e.preventDefault();
  $(this).tab('show');
});
$('.intro-box')
  .mouseenter(function() {
    $(this)
      .find('.intro')
      .addClass('intro-active');
  })
  .mouseleave(function() {
    $(this)
      .find('.intro')
      .removeClass('intro-active');
  });
$('.article-item').click(function(e) {
  e.preventDefault();
  var date_set = getDataset(this);
  var Id = date_set['id'];
  var type = date_set['type'];
  var parentType = date_set['parenttype'];
  var link =
    './zixun.html?&index=2&id=' +
    Id +
    '&type=' +
    type +
    '&parentType=' +
    parentType;
  // +"&type="+type+"&typeId="+typeId+"&index=2"
  window.location.href = link;
});
function getInforPager() {
  getInformationPager({
    pageIndex: 1,
    pageSize: 10,
    fCla: 0,
  })
    .done(res => {
      var list = res.content.rows;
      list.forEach(function(item) {
        item.type =
          item.fCla === 13
            ? 'qiye'
            : item.fCla === 14
            ? 'zhengce'
            : item.fCla === 15
            ? 'hangye'
            : '';
      });
      var compiled = _.template(
        document.getElementById('article-template').innerHTML
      );
      var html = compiled({ datas: list });
      $('#article-template-id').html(html);
    })
    .fail(err => {
      alert(err);
    });
}
function getBanner() {
  var isTest = location.host.match(/192|118/) != null;

  getAdList({ fCla: isTest ? 7 : 7 })
    .done(res => {
      var list = res.content;
      var compiled = _.template(
        document.getElementById('banner-template').innerHTML
      );
      var html = compiled({ datas: list });
      document.getElementById('myCarousel').innerHTML = html;
    })
    .fail(err => {
      // alert(err);
    });
}
getInforPager();
getBanner();

// Your jQuery code
