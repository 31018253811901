var url = '';
if (location.host.indexOf('zj') > -1 || location.host.indexOf('new') > -1 || location.host.indexOf('baoguanhao') > -1) {
  url =  'https://siteapi.zhonghuijingji.com';
  // url = 'https://siteapi.baoguanhao.com';

  // var path = 'http://' + location.host;
} else {
  // var path = "http://118.31.16.107"
  url = 'http://118.31.16.107:5023';

  // url = 'https://siteapi.baoguanhao.com';

  // var path = "https://www.zjyunduanbao.com"
}

module.exports = {
  url: url,
};
